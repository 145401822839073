import LocalizedLink from 'components/LocalizedLink';
import { ReactComponent as RewindIco } from 'assets/images/icons/Rewind.svg';

/**
 * Rewind button component
 * @param href - string, given to Link component
 * @param text - string, given to button for text
 * @returns {JSX.Element}
 * @constructor
 */
const RewindButton = ({ href, text }) => {
  return (
    <LocalizedLink to={href} className="btn btn-link">
      <RewindIco className="btn-ico btn-ico-left" />
      {text}
    </LocalizedLink>
  );
};

export default RewindButton;
