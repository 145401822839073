import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LocalizedLink = ({ to, className, type = 'link', children }) => {
  const { i18n } = useTranslation();

  return type === 'navlink' ? (
    <NavLink to={`/${i18n.language}${to}`} className={className}>
      {children}
    </NavLink>
  ) : (
    <Link to={`/${i18n.language}${to}`} className={className}>
      {children}
    </Link>
  );
};

export default LocalizedLink;
