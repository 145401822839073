import { useTranslation } from 'react-i18next';
import { footerTrans } from 'translations';
import { Container } from 'react-bootstrap';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <section className="c-section c-disclaimer">
      <Container>
        <p className="c-disclaimer__content">
          <strong>{t(footerTrans.heading)}</strong><br/>
          {t(footerTrans.body)}
        </p>
      </Container>
    </section>
  );
};

export default Footer;
