import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const languages = ['en', 'ru', 'lv', 'et', 'lt'];

function createLanguageBackendPath() {
  return {
    loadPath: '/api/v1/builder/translations',
    parse: data => {
      const parsedData = JSON.parse(data);
      const lang = i18n.language;
      return parsedData[lang];
    },
  };
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    backend: createLanguageBackendPath(),
    fallbackLng: languages[0],
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    supportedLngs: languages,
    detection: {
      order: ['path', 'localstorage'],
      checkWhitelist: true,
      possibleLanguages: languages,
    },
  });

export default i18n;
