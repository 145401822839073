import React from 'react';
import { Spinner } from 'react-bootstrap';

import './style.scss';

const LoadingSpinner = () => {
  return (
    <div className="spinner-wrap">
      <Spinner animation={'border'} role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default LoadingSpinner;
