import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setEngine, setSpecification } from 'store/entities/config';
import { CarPartCard } from 'components/Cards/CarPartCard';

export const EngineCard = ({ engineData, handleInfo }) => {
  const { specification } = useSelector(state => state.config);
  const { id, eic, title, price } = engineData;

  const dispatch = useDispatch();
  const handleSelectEngine = () => {
    dispatch(setSpecification(eic));
    dispatch(setEngine(id));
  };

  const isActive = useMemo(() => specification === eic, [specification, eic]);

  return (
    <CarPartCard
      id={id}
      title={title}
      price={price}
      engine={engineData}
      handleClick={handleSelectEngine}
      handleInfo={handleInfo}
      buttonText="choose_engine_button"
      isActive={isActive}
    />
  );
};
