import { useSelector } from 'react-redux';
import useAllAccessories from 'hooks/useAllAccessories';

const useAccessoriesPrice = () => {
  const { accessories } = useSelector(state => state.config);
  const allAccessories = useAllAccessories();

  let price = 0;
  accessories.forEach(accessory => {
    const foundAccessory = allAccessories.find(item => item.id === accessory);
    if (!!foundAccessory) price += foundAccessory.price;
  });
  return price;
};

export default useAccessoriesPrice;
