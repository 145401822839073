import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Accordion, ListGroup } from 'react-bootstrap';

import Price from 'components/Price';

const SelectedPackages = () => {
  const { t } = useTranslation();
  const { extraPackages } = useSelector(state => state.config);
  const { builderData } = useSelector(state => state.data);

  const packages = builderData.options.filter(option => extraPackages.includes(option.id));

  if (!extraPackages.length) return null;

  return (
    <Accordion.Item eventKey="accordionPackages">
      <Accordion.Button className="px-0 my-0 fs-5" as="h5" role="button">
        {t('selected_packages') + ` (${extraPackages.length})`}
      </Accordion.Button>
      <Accordion.Body className="px-0">
        <ListGroup variant="flush">
          {packages.map(item => 
          <ListGroup.Item key={item.code}>
            <span className="list-group-label">{item.name}</span>
            <b className="list-group-value"><Price price={item.price} placeholder="&ndash;" /></b>
          </ListGroup.Item>
          )}
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default SelectedPackages;
