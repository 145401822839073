import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { setModalState } from 'store/entities/app';

import OfferForm from './OfferForm';
import LoadingSpinner from 'components/LoadingSpinner';

const formDefaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  //phoneCode: '371',
  phoneNr: '',
  location: '1',
  consents: [],
};

export const OfferModal = () => {
  const [step, setStep] = useState('form');
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { offerModal: isOpen, offerModalType } = useSelector(state => state.app);
  const methods = useForm({ defaultValues: formDefaultValues });

  const handleClose = () => {
    const payload = {
      modal: 'offerModal',
      newState: false,
    };
    dispatch(setModalState(payload));
    setStep('form');
  };

  return (
    <FormProvider {...methods}>
      <Modal show={isOpen} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t(`offer_modal_${offerModalType}`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-5">
          <Row>
            <Col xs={12} lg={{ span: 6, offset: 3 }}>
              {step === 'form' && <OfferForm handleStepChange={setStep} />}
              {step === 'loading' && (
                <div className="mb-5">
                  <LoadingSpinner />
                </div>
              )}
              {step === 'finish' && (
                <div>
                  <p className="text-center">{t(`form_${offerModalType}_successful`)}</p>
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </FormProvider>
  );
};
