import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SwiperCore, { Navigation } from 'swiper';

import FeatureCarousel from 'components/AssemblySliderSelectors/FeatureCarousel';
import { setSelectedTab } from 'store/entities/app';

import { Tabs, Tab } from 'react-bootstrap';
import PlaceholderColor from 'assets/images/placeholder-color.png';
import PlaceholderWheels from 'assets/images/placeholder-alloy-wheel.png';
import PlaceholderInterior from 'assets/images/placeholder-interior.png';

SwiperCore.use([Navigation]);

const AssemblySliderSelectors = ({ onChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { colorCode, interior, wheels } = useSelector(state => state.config);
  const { builderData } = useSelector(state => state.data);
  const { selectedTab } = useSelector(state => state.app);

  const tabsData = [
    {
      id: 0,
      title: 'exterior_color',
      dataKey: 'exteriors',
      active: colorCode,
      placeholder: PlaceholderColor,
    },
    {
      id: 1,
      title: 'wheels',
      dataKey: 'wheels',
      active: wheels,
      placeholder: PlaceholderWheels,
    },
    {
      id: 2,
      title: 'interior',
      dataKey: 'interiors',
      active: interior,
      placeholder: PlaceholderInterior,
    },
  ];

  return (
    <Tabs
      className="c-threesixty__tabs"
      activeKey={selectedTab}
      onSelect={k => dispatch(setSelectedTab(k))}
    >
      {tabsData.map(tab => (
        <Tab eventKey={tab.dataKey} title={t(tab.title)} key={`tab-${tab.dataKey}`}>
          <FeatureCarousel
            activeItem={tab.active}
            setActiveItem={code => onChange({ code: code, check: false, type: tab.dataKey })}
            data={builderData[tab.dataKey]}
            placeholderImage={tab.placeholder}
          />
        </Tab>
      ))}
    </Tabs>
  );
};

export default AssemblySliderSelectors;