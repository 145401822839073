import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Collapse, ListGroup } from 'react-bootstrap';

import AccessoriesCategory from 'components/Accessories/AccessoriesCategory';
import { ReactComponent as ArrowDown } from 'assets/images/icons/ArrowDown.svg';

const SelectedAccessories = () => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  const { accessories } = useSelector(state => state.config);
  const { builderData } = useSelector(state => state.data);

  const { accessories: options } = builderData || {};

  if (!accessories.length) return null;

  return (
    <ListGroup.Item>
      <div
        className={`selected-accessories-toggle ${open ? 'selected-accessories-toggle-open' : ''}`}
        onClick={() => setOpen(!open)}
      >
        <div className="d-flex align-items-center justify-content-between py-2">
          <h5 className="mb-0">{t('selected_accessories') + `(${accessories.length})`}</h5>
          <ArrowDown />
        </div>
      </div>
      <Collapse in={open}>
        <div className="selected-accessories-list py-3">
          {Object.keys(options).map(category => (
            <AccessoriesCategory
              key={category}
              category={category}
              data={options[category]}
              hasMargin
              isConclusion
            />
          ))}
        </div>
      </Collapse>
    </ListGroup.Item>
  );
};

export default SelectedAccessories;
