import { CarPartCard } from 'components/Cards/CarPartCard';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { setBody, setEngine, setSpecification } from 'store/entities/config';

export const BodyCard = ({ bodyData, handleInfo }) => {
  const { body, specification } = useSelector(state => state.config);
  const { id, name, style, doors, seats, engines } = bodyData;

  const dispatch = useDispatch();
  const handleBodySelect = () => {
    dispatch(setBody(id));
    if (specification !== engines[0].eic) {
      dispatch(setSpecification(engines[0].eic));
      dispatch(setEngine(engines[0].id));
    }
  };

  const isActive = useMemo(() => body === id, [body, id]);

  return (
    <CarPartCard
      id={id}
      title={name}
      info={style}
      doors={doors}
      seats={seats}
      isActive={isActive}
      handleClick={handleBodySelect}
      handleInfo={handleInfo}
      buttonText="choose_body_button"
    />
  );
};
