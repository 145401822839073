import { Col, Placeholder } from 'react-bootstrap';

const ConfigLoadingPlaceholder = () => (
  <Col span={12}>
    <Placeholder animation="glow">
      <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />
      <Placeholder xs={6} />
      <Placeholder xs={8} />
    </Placeholder>
  </Col>
);

export default ConfigLoadingPlaceholder;
