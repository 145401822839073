export const asideTrans = {
  payment: 'Payment method',
  yourCar: 'Sinu auto',
  btn: 'Kokkuvõte',
  url: '/conclusion',
  popover: [
    {
      body: 'Kütusekulu ja CO2 heitkogus on mõõdetud seeriatootmise mudelil kontrollitud keskkonnas Euroopa Liidu uue kergsõidukite ülemaailmset ühtlustatud katsemenetlust (WLTP) käsitleva määruse (EL) 2017/1151 ja selle kehtivate muudatuste nõuete kohaselt.',
    },
    {
      link: 'Lisateave uue WLTP katsemeetodi kohta',
    },
    { url: 'somewhere' },
  ],
};

export const navTrans = {
  links: [
    {
      href: '/select',
      label: 'header_step_one',
    },
    {
      href: '/assemble',
      label: 'header_step_two',
    },
    {
      href: '/conclusion',
      label: 'header_step_three',
    },
  ],
};

export const assembleTrans = {
  popIdx: 'popover-0',
  popBody:
    'Kütusekulu ja CO2 heitkogus on mõõdetud seeriatootmise mudelil kontrollitud keskkonnas Euroopa Liidu uue kergsõidukite ülemaailmset ühtlustatud katsemenetlust (WLTP) käsitleva määruse (EL) 2017/1151 ja selle kehtivate muudatuste nõuete kohaselt.',

  popLink: 'Lisateave uue WLTP katsemeetodi kohta',

  popUrl: 'somewhere',
};

export const footerTrans = {
  heading: 'consent_title',
  body: 'consent_text',
  bg: 'bg-light',
};
