import { Card, Form } from 'react-bootstrap';

import Price from 'components/Price';
import PlaceholderAcc from 'assets/images/placeholder-acc.png';

export const AccessoryCard = ({ selected, onChange, accessoryData }) => {
  const { pictures } = accessoryData || {};

  return (
    <Card className="accessory-card">
      <Card.Img variant="top" src={pictures?.main || PlaceholderAcc} />
      <Card.ImgOverlay className="end-0 text-end">
        <Form.Check type="checkbox" checked={selected} onChange={onChange} />
      </Card.ImgOverlay>
      <Card.Body className="d-flex justify-content-between align-items-start">
        <Card.Text className="me-auto">
          <small className="fw-bold">{accessoryData?.name}</small>
          <br />
          <small><Price price={accessoryData?.price} /></small>
        </Card.Text>
        <div />
      </Card.Body>
    </Card>
  );
};
