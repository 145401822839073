import React from 'react';
import { Accordion, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useExtraPackagesPrice from 'hooks/useExtraPackagesPrice';
import Price from 'components/Price';

const SelectedPackages = () => {
  const { t } = useTranslation();

  const { extraPackages } = useSelector(state => state.config);
  const { builderData } = useSelector(state => state.data);
  const extraPackagesPrice = useExtraPackagesPrice();

  if (!extraPackages.length) return null;
  const packages = builderData.options.filter(option => extraPackages.includes(option.id));

  return (
    <Accordion flush>
      <Accordion.Item eventKey="accordionPackages">
        <Accordion.Button className="px-0 fw-bold">
          {t('selected_packages')}
          <span className="ms-1">({extraPackages.length})</span>
          <span className="ms-1"><Price price={extraPackagesPrice} /></span>
        </Accordion.Button>
        <Accordion.Body className="px-0">
          <ListGroup variant="flush">
            {packages.map(item => 
            <ListGroup.Item key={item.code}>
              <span className="list-group-label">{item.name}</span>
              <b className="list-group-value"><Price price={item.price} placeholder="&ndash;" /></b>
            </ListGroup.Item>
            )}
          </ListGroup>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default SelectedPackages;
