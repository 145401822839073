import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'react-bootstrap';

import { addAccessory, removeAccessory } from 'store/entities/config';
import { AccessoryCard } from 'components/Cards';

const AccessoriesCategory = ({ category, data, hasMargin = false, isConclusion = false }) => {
  const { t } = useTranslation();
  const [isShowingAll, setIsShowingAll] = useState(false);

  const dispatch = useDispatch();
  const { accessories } = useSelector(state => state.config);
  const { searchValue } = useSelector(state => state.app);

  const handleCheckbox = (isSelected, idx) =>
    isSelected ? dispatch(removeAccessory(idx)) : dispatch(addAccessory(idx));

  const isItemSelected = useCallback(
    id => !!accessories.filter(item => item === id).length,
    [accessories]
  );

  const filteredData = useMemo(
    () =>
      isConclusion
        ? data.filter(option => isItemSelected(option.id))
        : data?.filter(option => option.name.toLowerCase().includes(searchValue.toLowerCase())),
    [data, isConclusion, isItemSelected, searchValue]
  );

  if (!filteredData.length) return null;

  return (
    <>
      <Row>
        <Col xs={12} className="accessories-heading">
          <h4>{category}</h4>
          <span className="d-block">{t('category_units_no', { count: filteredData.length })}</span>
        </Col>
      </Row>
      <Row xs={1} sm={2} md={3} className="g-4">
        {filteredData
          .filter((option, idx) => {
            if (!isConclusion) {
              return isShowingAll ? true : idx < 3;
            }
            return isItemSelected(option.id);
          })
          .map((option, idx) => {
            const isSelected = isItemSelected(option.id);
            return (
              <Col key={`accbody-${idx}`}>
                <AccessoryCard
                  selected={isSelected}
                  onChange={() => handleCheckbox(isSelected, option.id)}
                  accessoryData={option}
                />
              </Col>
            );
          })}
      </Row>
      {!isConclusion && !isShowingAll && filteredData.length > 3 && (
        <div className={`mt-5 text-center ${hasMargin ? 'mb-5' : 'mb-2'}`}>
          <Button variant="light" onClick={() => setIsShowingAll(true)}>
            <strong>{t('category_units_one_more', { category })}</strong>
          </Button>
        </div>
      )}
    </>
  );
};

export default AccessoriesCategory;
