import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { Modal } from 'react-bootstrap';
import axios from 'axios';

import LoadingSpinner from 'components/LoadingSpinner';
import FinishScreen from 'components/modals/SaveCarConfigModal/FinishScreen';
import ErrorScreen from 'components/modals/SaveCarConfigModal/ErrorScreen';
import { setModalState } from 'store/entities/app';

export const SaveCarConfigModal = () => {
  const [savedConfigId, setSavedConfigId] = useState(null);
  const [hasError, setHasError] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { config } = useSelector(state => state);
  const { saveConfigModal: isOpen } = useSelector(state => state.app);

  const { isFetching: isSavingConfig, mutate: saveConfig } = useMutation(
    'saveConfig',
    async () =>
      axios.post(`/builder/config`, {
        config: {
          ...config,
        },
      }),
    {
      enabled: false,
      onSuccess: res => setSavedConfigId(res?.data?.configId),
      onError: () => setHasError(true),
    }
  );

  useEffect(() => {
    if (isOpen) {
      saveConfig();
    }
  }, [saveConfig, isOpen]);

  const resetState = () => {
    setSavedConfigId(null);
    setHasError(false);
  };

  const handleClose = () => {
    resetState();
    const payload = {
      modal: 'saveConfigModal',
      newState: false,
    };
    dispatch(setModalState(payload));
  };

  return (
    <Modal show={isOpen} onHide={handleClose} size="lg" animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{t('modal_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="py-5 position-relative">
          {isSavingConfig && <LoadingSpinner />}
          {savedConfigId && <FinishScreen code={savedConfigId} />}
          {hasError && <ErrorScreen onReset={resetState} />}
        </div>
      </Modal.Body>
    </Modal>
  );
};
