import { Col, Container, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Navigation, Mousewheel, Keyboard } from 'swiper';
import Swatch from 'components/Swatch';
import Price from 'components/Price';
import { ReactComponent as ArrowLeft } from 'assets/images/icons/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from 'assets/images/icons/ArrowRight.svg';

const FeatureCarousel = ({ activeItem, setActiveItem, data, placeholderImage }) => {
  if (!data) return null;

  const { name = '', price = 0 } = data.find(item => item.code === activeItem) || {};
  const mappedData = [...data].sort((a, b) => a.price - b.price).reduce((mapped, item) => {
    (mapped[item.price] = mapped[item.price] || []).push(item);
    return mapped;
  }, {});

  return (
    <div className="c-threesixty__colors">
      <p className="c-threesixty__descr">
        <span className="c-threesixty__title">{name}</span>
        {!!price && <strong className="c-threesixty__price"><Price price={price} /></strong>}
      </p>
      <Swiper
        className="swatch-swiper"
        cssMode={true}
        slidesPerView={'auto'}
        navigation={{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Mousewheel, Keyboard]}
      >
        {!data.categories && Object.keys(mappedData).map(key =>
          <SwiperSlide key={`swatchGroup-${key}`}>
            <div className="swiper-group">
              {mappedData[key].map(radio =>
                <Swatch
                  key={`swatch-${radio.code}`}
                  img={radio.pictures?.thumb || placeholderImage}
                  name={radio.name}
                  value={radio.code}
                  selectedValue={activeItem}
                  setSelected={() => setActiveItem(radio.code)}
                />
              )}
              <span className="swiper-label">+ <Price price={key} /></span>
            </div>
          </SwiperSlide>
        )}
        <button className="swiper-button swiper-button-prev" type="button"><ArrowLeft /></button>
        <button className="swiper-button swiper-button-next" type="button"><ArrowRight /></button>
      </Swiper>
    </div>
  );
};

export default FeatureCarousel;