import { Image, ToggleButton } from 'react-bootstrap';
import { ReactComponent as CheckIco } from 'assets/images/icons/Check.svg';

const Swatch = ({ img, name, value, selectedValue, setSelected }) => {
  const isSelected = value === selectedValue;

  return (
    <ToggleButton
      key={name}
      id={value || name.replace(/\s/g, '')}
      name={name}
      value={value}
      variant="link"
      type="radio"
      className={`c-swatch${isSelected ? ' is-active' : ''}`}
      checked={isSelected}
      onChange={() => setSelected(value || undefined)}
    >
      <Image src={img} />
      {isSelected && <CheckIco className="c-swatch__ico" />}
    </ToggleButton>
  );
};

export default Swatch;
