import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';

import { setModalState } from 'store/entities/app';
import useTotalPrice from 'hooks/useTotalPrice';
import useBreakPoints from 'hooks/useBreakPoints';

import Price from 'components/Price';

const StickyAside = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { builderData } = useSelector(state => state.data);
  const totalPrice = useTotalPrice();
  const { isSmallerThanLg } = useBreakPoints();

  return (
    <aside className="c-aside">
      <div className="c-aside__head">
        <p className="c-aside__title">
          {t('your_configured_car', { model: builderData.model.name })}
        </p>
        <h6 className="c-aside__price"><Price price={totalPrice} /></h6>
      </div>
      <p className="c-aside__btnline">
        <Button
          variant="primary"
          onClick={() => dispatch(setModalState({modal: 'offerModal', newState: true, type: 'offer'}))}
        >
          {t('ask_offer_button')}
        </Button>
        <Button
          variant="light"
          onClick={() => dispatch(setModalState({modal: 'offerModal', newState: true, type: 'testdrive'}))}
        >
          {t('book_test_button')}
        </Button>
        {!isSmallerThanLg && <Button
          variant="light"
          onClick={() => dispatch(setModalState({modal: 'pdfModal', newState: true}))}
        >
          {t('pdf_button')}
        </Button>}
      </p>
    </aside>
  );
};

export default StickyAside;
