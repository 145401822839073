import { useSelector } from 'react-redux';

const useAllAccessories = () => {
  const { builderData } = useSelector(state => state.data);
  const { accessories } = builderData;

  if (!accessories) return 0;

  return [].concat(...Object.keys(accessories).map(key => accessories[key]));
};

export default useAllAccessories;
