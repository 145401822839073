import axios from 'axios';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router';
import { useQuery } from 'react-query';

import NavBar from 'components/NavBar';
import Assemble from 'views/Assemble';
import CarPartSelection from 'views/CarPartSelection';
import Conclusion from 'views/Conclusion';
import GradeSelection from 'views/GradeSelection';
import Config from 'views/Config';
import Footer from 'components/Footer';
import { setLocations } from 'store/entities/app';

const Router = () => {
  const dispatch = useDispatch();

  useQuery(
    'locations',
    async () => {
      const res = await axios.get('/location');
      return res.data;
    },
    {
      onSuccess: res => dispatch(setLocations(res)),
    }
  );

  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route path="/" element={<Navigate to="/grade" />} />
        <Route path="/:locale/select" element={<CarPartSelection />} />
        <Route path="/:locale/assemble" element={<Assemble />} />
        <Route path="/:locale/conclusion" element={<Conclusion />} />
        <Route path="/:locale/grade" element={<GradeSelection />} />
        <Route path="/:locale/config" element={<Config />} />
        <Route path="*" element={<GradeSelection />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default Router;
