import { combineReducers } from 'redux';

import config from './entities/config';
import app from './entities/app';
import data from './entities/data';

export default combineReducers({
  config,
  app,
  data,
});
