import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import queryString from 'query-string';

import { Container } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper';

import axios from 'axios';

import LoadingSpinner from 'components/LoadingSpinner';
import { setSpecificationsData } from 'store/entities/data';

import { GradeCard } from 'components/Cards';
import { ReactComponent as ArrowLeft } from 'assets/images/icons/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from 'assets/images/icons/ArrowRight.svg';

const GradeSelection = () => { //const state = useSelector(state => state); console.log('state', state);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const { specificationsData } = useSelector(state => state.data);
  const { id: model, code } = queryString.parse(location.search);

  const ENV = window.ENV[window.location.hostname] || window.ENV.default;

  const { isFetching, refetch: fetchData } = useQuery(
    ['specifications', model || code],
    async () =>
      axios.get('/builder/specifications', {
        params: {
          'brand.name': ENV.BRAND_NAME,
          'model.id': model,
          'model.code': code,
          isActive: true,
        },
      }),
    {
      enabled: false,
      onSuccess: res => dispatch(setSpecificationsData(res.data)),
    }
  );

  useEffect(() => {
    (model || code) ? fetchData() : window.location.replace(ENV.FALLBACK_URL);
  }, [model, code, fetchData, ENV]);

  return (
    <>
      {isFetching && <LoadingSpinner />}
      <section className="c-section c-grades">
        <Container>
          <h5 className="c-section__subheader">{t('grade_selection_descr')}</h5>

          <Swiper
            className="card-swiper"
            cssMode={true}
            slidesPerView={'auto'}
            navigation={{
              prevEl: '.swiper-button-prev',
              nextEl: '.swiper-button-next',
            }}
            pagination={{
              clickable: true,
            }}
            mousewheel={true}
            keyboard={true}
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          >
            {Object.keys(specificationsData).map(key =>
              [...specificationsData[key]].sort(
                (a, b) => a.price - b.price)[0] || null
              ).sort(
                (a, b) => a.price - b.price).map(grade =>
                  <SwiperSlide key={grade.eic}>
                    <GradeCard gradeData={grade} />
                  </SwiperSlide>
            )}
            <button className="swiper-button swiper-button-prev" type="button">
              <ArrowLeft />
            </button>
            <button className="swiper-button swiper-button-next" type="button">
              <ArrowRight />
            </button>
          </Swiper>

          {!isFetching && !Object.keys(specificationsData).length && (
            <h5 className="c-section__subheader">No cars found</h5>
          )}
        </Container>
      </section>
    </>
  );
};

export default GradeSelection;
