import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ListGroup } from 'react-bootstrap';

import SelectedAccessories from 'components/BottomBar/SelectedAccessories';
import SelectedPackages from 'components/BottomBar/SelectedPackages';
import Price from 'components/Price';

const Cart = () => {
  const { t } = useTranslation();

  const { builderData } = useSelector(state => state.data);
  const { colorCode, interior, wheels } = useSelector(state => state.config);

  const selectedExterior = builderData.exteriors?.find(item => item.code === colorCode);
  const selectedInterior = builderData.interiors?.find(item => item.code === interior);
  const selectedWheel = builderData.wheels?.find(item => item.code === wheels);

  return (
    <div className="c-cart">
      <header className="c-cart__head">
          <h4 className="c-cart__title">{builderData.model?.name}</h4>
          <p className="c-cart__subtitle">{builderData.name}</p>
      </header>
      <div className="c-cart__body">
        <ListGroup variant="flush">
          <ListGroup.Item>
            <span className="list-group-label">{t('price')}</span>
            <b className="list-group-value"><Price price={builderData.price} /></b>
          </ListGroup.Item>
          {selectedExterior &&
          <ListGroup.Item>
            <span className="list-group-label">{selectedExterior.name}</span>
            <b className="list-group-value"><Price price={selectedExterior.price} placeholder="&ndash;" /></b>
          </ListGroup.Item>
          }
          {selectedInterior &&
          <ListGroup.Item>
            <span className="list-group-label">{selectedInterior.name}</span>
            <b className="list-group-value"><Price price={selectedInterior.price} placeholder="&ndash;" /></b>
          </ListGroup.Item>
          }
          {selectedWheel &&
          <ListGroup.Item>
            <span className="list-group-label">{selectedWheel.name}</span>
            <b className="list-group-value"><Price price={selectedWheel.price} placeholder="&ndash;" /></b>
          </ListGroup.Item>
          }
          <SelectedAccessories />
          <SelectedPackages />
        </ListGroup>
      </div>
      <footer className="c-cart__foot">
        <strong className="c-cart__hint">{t('consent_short_title')}</strong>
        <p className="c-cart__hint">{t('consent_short_text')}</p>
      </footer>
    </div>
  );
};

export default Cart;
