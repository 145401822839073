import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Price from 'components/Price';

export const PackagesModal = ({ show, data, target, onConfirm, onCancel }) => {
  const { t } = useTranslation();

  if (!data) return null;

  const action = target ? target.check : target;
  const required = !!data.requiredOptions;
  const incompatible = !!data.incompatibleOptions;

  return (
    <Modal show={show} onHide={onCancel} animation>
      <Modal.Header closeButton>
        <Modal.Title>{t(required && incompatible ? 'package_title_combined' : incompatible ? 'package_title_incompatible' : 'package_title_required')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="c-selection">
          <h5 className="c-selection__title">{t(action ? 'package_remove' : 'package_add')}</h5>
          <ul className="c-selection__list">
            <li className="c-selection__item c-item">
              <svg className={`c-item__ico c-item__ico--${action ? 'del' : 'add'}`} viewBox="0 0 20 20">
                <path className="c-item__ico-bg" d="M10 19.1c5 0 9.1-4.1 9.1-9.1S15 .9 10 .9.9 5 .9 10 5 19.1 10 19.1zm0 .9C4.5 20 0 15.5 0 10S4.5 0 10 0s10 4.5 10 10-4.5 10-10 10z"></path>
                <path className="c-item__ico-sign" d={action ? "M13.3 9.5v1.1H6.8V9.5h3.9z" : "M13.3 9.5v1.1h-2.6v2.8H9.5v-2.8H6.8V9.5h2.7V6.8h1.2v2.7z"}></path>
              </svg>
              <p className="c-item__descr">
                <span className="c-item__title">{data.option.name}</span>
                <span className="c-item__code">{data.option.code}</span>
              </p>
              <b className="c-item__price"><Price price={data.option.price} placeholder="" /></b>
            </li>
          </ul>

          {incompatible && <>
          <h5 className="c-selection__title">{t('will_removed')}</h5>
          <ul className="c-selection__list">
            {data.incompatibleOptions.map((set) => set.map((item) =>
            <li className="c-selection__item c-item" key={item.code}>
              <svg className="c-item__ico c-item__ico--del" viewBox="0 0 20 20">
                <path className="c-item__ico-bg" d="M10 19.1c5 0 9.1-4.1 9.1-9.1S15 .9 10 .9.9 5 .9 10 5 19.1 10 19.1zm0 .9C4.5 20 0 15.5 0 10S4.5 0 10 0s10 4.5 10 10-4.5 10-10 10z"></path>
                <path className="c-item__ico-sign" d="M13.3 9.5v1.1H6.8V9.5h3.9z"></path>
              </svg>
              <p className="c-item__descr">
                <span className="c-item__title">{item.name}</span>
                <span className="c-item__code">{item.code}</span>
              </p>
              <b className="c-item__price"><Price price={item.price} placeholder="" /></b>
            </li>
            ))}
          </ul>
          </>}

          {required && <>
          <h5 className="c-selection__title">{t('will_added')}</h5>
          <ul className="c-selection__list">
            {data.requiredOptions.map((set) => set.map((item) =>
            <li className="c-selection__item c-item" key={item.code}>
              <svg className="c-item__ico c-item__ico--add" viewBox="0 0 20 20">
                <path className="c-item__ico-bg" d="M10 19.1c5 0 9.1-4.1 9.1-9.1S15 .9 10 .9.9 5 .9 10 5 19.1 10 19.1zm0 .9C4.5 20 0 15.5 0 10S4.5 0 10 0s10 4.5 10 10-4.5 10-10 10z"></path>
                <path className="c-item__ico-sign" d="M13.3 9.5v1.1h-2.6v2.8H9.5v-2.8H6.8V9.5h2.7V6.8h1.2v2.7z"></path>
              </svg>
              <p className="c-item__descr">
                <span className="c-item__title">{item.name}</span>
                <span className="c-item__code">{item.code}</span>
              </p>
              <b className="c-item__price"><Price price={item.price} placeholder="" /></b>
            </li>
            ))}
          </ul>
          </>}

          <div className="c-selection__diff c-diff">
            <div className="c-diff__item">
              <span className="c-diff__title">{t('initial_price')}:</span>
              <b className="c-diff__price"><Price price={data.priceBefore} /></b>
            </div>
            <div className="c-diff__item c-diff__item--primary">
              <span className="c-diff__title">{t('new_price')}:</span>
              <b className="c-diff__price"><Price price={data.priceResolved} /></b>
            </div>
          </div>
          <div className="c-selection__btnline">
            <Button onClick={onCancel} variant="light">{t('btn_cancel')}</Button>
            <Button onClick={onConfirm} variant="secondary">{t('btn_confirm')}</Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
