import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from 'react-bootstrap';
import { ReactComponent as CartIco } from 'assets/images/icons/Cart.svg';
import { ReactComponent as ArrowUp } from 'assets/images/icons/ArrowUp.svg';

import { useDisableBodyScroll } from 'hooks/useDisableBodyScroll';
import useBreakPoints from 'hooks/useBreakPoints';
import useTotalPrice from 'hooks/useTotalPrice';

import Cart from 'components/BottomBar/Cart';
import Price from 'components/Price';

const BottomBar = ({ action }) => {
  const { t } = useTranslation();
  const totalPrice = useTotalPrice();

  const [isExpanded, setIsExpanded] = useState(false);

  const { builderData } = useSelector(state => state.data);
  const { isSmallerThanLg } = useBreakPoints();
  useDisableBodyScroll(isExpanded && isSmallerThanLg);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <footer className={`c-bottombar${isExpanded ? ' is-expanded' : ''}`}>
      <div className="c-bottombar__head">
        {isSmallerThanLg ? (
        <><Button onClick={toggleExpand} variant="link" className="c-bottombar__toggle c-toggle">
            <ArrowUp className="c-toggle__arrow" />
            {!isExpanded && <CartIco className="c-toggle__cart" />}
          </Button>
          <p className="c-bottombar__info c-info">
            <span className="c-info__title">{t('your_car')}</span>
            <b className="c-info__price"><Price price={totalPrice} /></b>
          </p></>
        ) : (
          <p className="c-bottombar__info c-info">
            <b className="c-info__price">{t('total_price')} <Price price={totalPrice} /></b>
            <span className="c-info__title">{builderData.name}</span>
          </p>
        )}
        <p className="c-bottombar__btnline">
          {action}
        </p>
      </div>
      {isSmallerThanLg && 
        <div className="c-bottombar__body">
          <Cart/>
        </div>
      }
    </footer>
  );
};

export default BottomBar;
