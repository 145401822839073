import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Modal, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const TechDataModal = ({ show, onClose, type = 'techdata' }) => {
  const { t } = useTranslation();
  const { builderData } = useSelector(state => state.data);

  const { techdata, equipment } = builderData;

  const mappedEquipmentData = useMemo(
    () =>
      equipment?.reduce((obj, el) => {
        obj[el.categoryName] = [...(obj[el.categoryName] || []), el];
        return obj;
      }, {}),
    [equipment]
  );

  return (
    <Modal show={show} onHide={onClose} size="lg" animation>
      <Modal.Header closeButton>
        <Modal.Title>{t(type === 'techdata' ? 'technical_spec' : 'equipment')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {type === 'techdata' ? (
            Object.keys(techdata || {}).map(key => (
              <Col xs={12} key={key}>
                <h6>{key}</h6>
                <ListGroup variant="flush" className="mb-2">
                  {techdata[key].map((item, idx) =>
                    <ListGroup.Item key={`${item.name}-${idx}`}>
                      <span className="list-group-label">{item.name}</span>
                      <b className="list-group-value">{item.value}</b>
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Col>
            ))
          ) : (
            <>
              <Col xs={12} lg={6}>
                {Object.keys(mappedEquipmentData || {}).map((key, idx) =>
                  idx % 2 === 0 ? (
                    <Col xs={12} key={key}>
                      <h6>{key}</h6>
                      <ul>
                        {mappedEquipmentData[key].map((item, idx) => (
                          <li key={`${item.name}-${idx}`}>{item.name}</li>
                        ))}
                      </ul>
                    </Col>
                  ) : null
                )}
              </Col>
              <Col xs={12} lg={6}>
                {Object.keys(mappedEquipmentData || {}).map((key, idx) =>
                  idx % 2 !== 0 ? (
                    <Col xs={12} key={key}>
                      <h6>{key}</h6>
                      <ul>
                        {mappedEquipmentData[key].map((item, idx) => (
                          <li key={`${item.name}-${idx}`}>{item.name}</li>
                        ))}
                      </ul>
                    </Col>
                  ) : null
                )}
              </Col>
            </>
          )}
        </Row>
      </Modal.Body>
    </Modal>
  );
};
