import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const FinishScreen = ({ code, option }) => {
  const { t, i18n } = useTranslation();
  const configUrl = `${window.origin}/${i18n.language}/config?id=${code}`;

  return (
    <Row>
      <Col xs={12} lg={{ span: 8, offset: 2 }}>
        <h2 className="mb-4">{t('config_save_succ_title')}</h2>
        {option === 'lead' && <p>MISSING_TRANSLATION</p>}
        <p>
          {t('config_save_succ_text')} <a href={configUrl}>{configUrl}</a>
        </p>
      </Col>
    </Row>
  );
};

export default FinishScreen;
