import { Col } from 'react-bootstrap';

const ConfigFailedLoading = () => (
  <Col span={12}>
    <h3>Error 404</h3>
    <p>Config not found</p>
  </Col>
);

export default ConfigFailedLoading;
