import { Row, Col, Tabs, Tab, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Price from 'components/Price';

const Packages = ({ onChange }) => { //const state = useSelector(state => state); console.log('state', state);
  const { t } = useTranslation();

  const { extraPackages } = useSelector(state => state.config);
  const { builderData } = useSelector(state => state.data);
  const { options = [], equipment = [] } = builderData;

  const categories = [...new Set([...options, ...equipment].map(item => item.categoryName || 'OTHER'))];

  return (
    <Tabs defaultActiveKey={`tab-${categories[0]}`}>
      {categories.map((category) => {
        const opts = options.filter(item => (item.categoryName || 'OTHER') === category);
        const eqpt = equipment.filter(item => (item.categoryName || 'OTHER') === category);
        return (
          <Tab eventKey={`tab-${category}`} title={category} key={`tab-${category}`}>
            <Row className="mt-5">
              {opts.map((item) => {
                const isChecked = extraPackages.includes(item.id);
                if (!item.price) return null;
                return (
                  <Col sm="6" lg="3" className="mb-4" key={item.code}>
                    <div className={`c-package ${isChecked ? 'is-active' : ''}`}>
                      {/*<figure className="c-package__img">
                        <span className="c-package__code">{item.code}</span>
                      </figure>*/}
                      <div className="c-package__body">
                        <span className="c-package__title">{item.name}</span>
                      </div>
                      <div className="c-package__footer">
                        <strong className="c-package__price c-price"><Price price={item.price} /></strong>
                        <Button
                          className="c-package__btn"
                          variant={isChecked ? "primary" : "secondary"}
                          size="sm"
                          onClick={() => onChange({ code: item.code, check: isChecked, type: 'options' })}
                        >
                          <svg className="btn-ico btn-ico-left" viewBox="0 0 20 20">
                            <path className="btn-ico-bg" d="M10 19.1c5 0 9.1-4.1 9.1-9.1S15 .9 10 .9.9 5 .9 10 5 19.1 10 19.1zm0 .9C4.5 20 0 15.5 0 10S4.5 0 10 0s10 4.5 10 10-4.5 10-10 10z"></path>
                            <path className="btn-ico-sign" d={isChecked ? "M13.3 9.5v1.1H6.8V9.5h3.9z" : "M13.3 9.5v1.1h-2.6v2.8H9.5v-2.8H6.8V9.5h2.7V6.8h1.2v2.7z"}></path>
                          </svg>
                          {t('choose_body_button')}
                        </Button>
                      </div>
                    </div> 
                  </Col>
                );
              })}
            </Row>
            <Row className="mt-3">
              {opts.map((item) => {
                const isChecked = extraPackages.includes(item.id);
                if (item.price) return null;
                return (
                  <Col md="6" key={item.code}>
                    <div className={`c-pkg ${isChecked ? 'is-active' : ''}`}>
                      <div className="c-pkg__body">
                        <span className="c-pkg__title">{item.name}</span>
                      </div>
                      <div className="c-pkg__footer">
                        <Button
                          className="c-pkg__btn"
                          variant={isChecked ? "primary" : "secondary"}
                          size="sm"
                          title={t('choose_body_button')}
                          onClick={() => onChange({ code: item.code, check: isChecked, type: 'options' })}
                        >
                          <svg className="btn-ico" viewBox="0 0 20 20">
                            <path className="btn-ico-bg" d="M10 19.1c5 0 9.1-4.1 9.1-9.1S15 .9 10 .9.9 5 .9 10 5 19.1 10 19.1zm0 .9C4.5 20 0 15.5 0 10S4.5 0 10 0s10 4.5 10 10-4.5 10-10 10z"></path>
                            <path className="btn-ico-sign" d={isChecked ? "M13.3 9.5v1.1H6.8V9.5h3.9z" : "M13.3 9.5v1.1h-2.6v2.8H9.5v-2.8H6.8V9.5h2.7V6.8h1.2v2.7z"}></path>
                          </svg>
                        </Button>
                      </div>
                    </div> 
                  </Col>
                );
              })}
            </Row>
            <Row>
              {eqpt.map((item) => {
                return (
                  <Col md="6" key={item.code}>
                    <div className="c-pkg">
                      <div className="c-pkg__body">
                        <span className="c-pkg__title">{item.name}</span>
                      </div>
                    </div> 
                  </Col>
                );
              })}
            </Row>
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default Packages;
