import { Button, Col, Row } from 'react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ErrorScreen = ({ onReset }) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col xs={12} lg={{ span: 8, offset: 2 }}>
        <h2 className="mb-4">{t('config_send_err_title')}</h2>
        <p>{t('config_send_err_text')}</p>
      </Col>
      <Col xs={12} className="d-flex align-center justify-content-center">
        <Button onClick={onReset}>{t('config_send_err_button')}</Button>
      </Col>
    </Row>
  );
};

export default ErrorScreen;
