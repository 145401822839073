import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Container, Button, Collapse } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { navTrans } from 'translations';
import { ReactComponent as ArrowLeft } from 'assets/images/icons/ArrowLeft.svg';
import { ReactComponent as ArrowUp } from 'assets/images/icons/ArrowUp.svg';
import LocalizedLink from 'components/LocalizedLink';

const NavBar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { builderData, specificationsData } = useSelector(state => state.data);
  const { model } = builderData;
  const title = model ? model.name : Object.values(specificationsData).length ? Object.values(specificationsData)[0][0].model.name : t('first_step_title');

  const showBackButton = useMemo(() => !location.pathname.includes('/grade'), [location.pathname]);

  return (
    <>
    <header className="c-header">
      <Container>
        {showBackButton && <LocalizedLink
          to={model ? `/grade?id=${model.id}` : '/grade'}
          className="c-header__back c-back"
        >
          <ArrowLeft className="c-back__ico" />
          <span className="c-back__label">{t('header_back')}</span>
        </LocalizedLink>}
        <h2 className="c-header__title">{title}</h2>
      </Container>
    </header>
    <nav className="c-steps">
      <Container>
        <Button className={`c-steps__toggler${open ? ' is-active' : ''}`} variant="link" onClick={() => setOpen(!open)}>
          <ArrowUp className="c-ico" />
        </Button>
        <Collapse in={open}>
        <div className="c-steps__collapse">
          <div className="c-steps__nav">
            {navTrans.links.map((link, idx) => (
              <LocalizedLink
                key={`navlink-${idx}`}
                to={link.href}
                type="navlink"
                className="c-steps__link"
              >
                <span className="c-steps__text">
                  <small className="c-steps__count">{idx + 1}</small>
                  <span className="c-steps__label">{t(link.label)}</span>
                </span>
              </LocalizedLink>
            ))}
          </div>
        </div>
        </Collapse>
      </Container>
    </nav>
    </>
  );
};

export default NavBar;
