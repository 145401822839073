import { useEffect } from 'react';

export const useDisableBodyScroll = isDisabled => {
  useEffect(() => {
    if (isDisabled) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isDisabled]);
};
