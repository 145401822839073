import { useSelector } from 'react-redux';
import { getSelectedAccessory } from 'utils/accessoryUtils';

const useSelectedAccessory = () => {
  const { engine, body, colorCode, interior, wheels } = useSelector(state => state.config);
  const { builderData } = useSelector(state => state.data);
  const { engines, bodies, exteriors, interiors, wheels: wheelsData } = builderData || {};

  return {
    engine:
      getSelectedAccessory(engines, engine, null, {
        key: 'id',
      })?.[0] || null,
    body:
      getSelectedAccessory(bodies, body, null, {
        key: 'id',
      })?.[0] || null,
    wheels: getSelectedAccessory(wheelsData, wheels, null)?.[0] || null,
    exterior: getSelectedAccessory(exteriors, colorCode, null)?.[0] || null,
    interior: getSelectedAccessory(interiors, interior, null)?.[0] || null,
  };
};

export default useSelectedAccessory;
